import { useMemo } from 'react';
import { usePartnerStore } from '@/store/partner-store';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import {
  BUSINESS_ENDPOINTS,
  businessInviteSetupVerifyToken,
  getAllCalendarEvents,
  getAllPartnerBusinesses,
  getBusinessAuthMethods,
  getBusinessById,
  getBusinessPartners,
  getBusinessPartnersDetails,
  getBusinessTeamMembers,
  getBusinessTeamMembersDetails,
  getCurrentBusiness,
} from '@/services/business';
import {
  TBusiness,
  TBusinessInviteSetupVerifyTokenRequest,
  TGetAllCalendarEventsRequest,
  TGetBusinessAuthMethodsRequest,
  TGetBusinessByIdRequest,
  TGetBusinessPartnersDetailsRequest,
  TGetBusinessPartnersRequest,
  TGetBusinessTeamMembersDetailsRequest,
  TGetBusinessTeamMembersRequest,
  TGetCurrentBusinessRequest,
  TGetPartnerBusinessesRequest,
} from '@/services/business/business.types';
import { USER_TYPE } from '@/services/user';

import { type UseQueryOptions } from '../types';
import { useGetCurrentUser } from '../user';

export function useGetBusinessById({ reactQueryOptions, params }: UseQueryOptions<TGetBusinessByIdRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_BY_ID, params.path.id],
    queryFn: ({ signal }) => getBusinessById({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetBusinessAuthMethods({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessAuthMethodsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_AUTH_METHODS, params.path.businessId],
    queryFn: ({ signal }) => getBusinessAuthMethods({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetCurrentBusiness({ reactQueryOptions }: UseQueryOptions<TGetCurrentBusinessRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_CURRENT_BUSINESS],
    queryFn: ({ signal }) => getCurrentBusiness({ signal }),
    staleTime: Infinity,
  });
}

export function useGetCurrentPartnerBusiness() {
  const { data: partnerStore } = usePartnerStore();
  const partnerBusinessId = partnerStore?.selectedBusiness ? parseInt(partnerStore?.selectedBusiness) : null;

  return useGetBusinessById({
    params: {
      path: {
        id: partnerBusinessId,
      },
    },
    reactQueryOptions: {
      enabled: !!partnerBusinessId,
    },
  });

  // const { data: partnerBusiness } = useGetBusinessById({
  //   params: {
  //     path: {
  //       id: partnerBusinessId,
  //     },
  //   },
  //   reactQueryOptions: {
  //     enabled: !!partnerBusinessId,
  //   },
  // });

  // return useMemo(() => {
  //   if (currentuser?.user_type === USER_TYPE.PARTNER) {
  //     return partnerBusiness;
  //   } else {
  //     return undefined;
  //   }
  // }, [currentuser, partnerBusiness]);
}

export function useGetCurrentRoleBusiness(): TBusiness | undefined {
  const { data: partnerStore } = usePartnerStore();
  const { data: currentuser } = useGetCurrentUser({});
  const { data: currentBusiness } = useGetCurrentBusiness({});
  const partnerBusinessId = partnerStore?.selectedBusiness ? parseInt(partnerStore?.selectedBusiness) : null;

  const { data: partnerBusiness } = useGetBusinessById({
    params: {
      path: {
        id: partnerBusinessId,
      },
    },
    reactQueryOptions: {
      enabled: !!partnerBusinessId,
    },
  });

  return useMemo(() => {
    if (currentuser?.user_type === USER_TYPE.PARTNER) {
      return partnerBusiness;
    } else if (currentuser?.user_type === USER_TYPE.BUSINESS) {
      return currentBusiness;
    } else {
      return undefined;
    }
  }, [currentuser, partnerBusiness, currentBusiness]);
}

export function useGetCurrentPartner({ reactQueryOptions }: UseQueryOptions<TGetCurrentBusinessRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_CURRENT_BUSINESS],
    queryFn: ({ signal }) => getCurrentBusiness({ signal }),
    staleTime: Infinity,
  });
}

export function useGetCurrentBusinessSuspense({ reactQueryOptions }: UseQueryOptions<TGetCurrentBusinessRequest>) {
  return useSuspenseQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_CURRENT_BUSINESS],
    queryFn: ({ signal }) => getCurrentBusiness({ signal }),
    staleTime: Infinity,
  });
}

export function useGetAllCalendarEvents({ reactQueryOptions }: UseQueryOptions<TGetAllCalendarEventsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_ALL_CALENDAR_EVENTS],
    queryFn: ({ signal }) => getAllCalendarEvents({ signal }),
    staleTime: Infinity,
  });
}

export function useGetBusinessTeamMembers({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessTeamMembersRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS, params?.path.businessId, reactQueryOptions?.queryKey],
    queryFn: ({ signal }) =>
      getBusinessTeamMembers({
        signal,
        params: {
          path: {
            businessId: params?.path.businessId,
          },
          query: {
            ...params?.query,
            sort: params?.query?.sort || 'last_active',
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}

export function useGetBusinessPartners({ reactQueryOptions, params }: UseQueryOptions<TGetBusinessPartnersRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS, params?.path.businessId, reactQueryOptions?.queryKey],
    queryFn: ({ signal }) =>
      getBusinessPartners({
        signal,
        params: {
          path: {
            businessId: params?.path.businessId,
          },
          query: {
            ...params?.query,
            sort: params?.query?.sort || 'account_type',
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}

export function useGetBusinessTeamMembersDetails({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessTeamMembersDetailsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS_DETAILS, params.path.businessId, params.path.memberId],
    queryFn: ({ signal }) => getBusinessTeamMembersDetails({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetBusinessTeamMembersDetailsSuspense({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessTeamMembersDetailsRequest>) {
  return useSuspenseQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS_DETAILS, params.path.businessId, params.path.memberId],
    queryFn: ({ signal }) => getBusinessTeamMembersDetails({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetBusinessPartnersDetails({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessPartnersDetailsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS_DETAILS, params.path.businessId, params.path.partnerId],
    queryFn: ({ signal }) => getBusinessPartnersDetails({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetBusinessPartnersDetailsSuspense({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessPartnersDetailsRequest>) {
  return useSuspenseQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS_DETAILS, params.path.businessId, params.path.partnerId],
    queryFn: ({ signal }) => getBusinessPartnersDetails({ signal, params }),
    staleTime: Infinity,
  });
}

// Business Invitations setup

export function useBusinessInviteSetupVerifyToken({
  reactQueryOptions,
  params,
}: UseQueryOptions<TBusinessInviteSetupVerifyTokenRequest>) {
  return useSuspenseQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.BUSINESS_INVITE_SETUP_VERIFY_TOKEN, params.path.token],
    queryFn: ({ signal }) => businessInviteSetupVerifyToken({ signal, params }),
    staleTime: Infinity,
  });
}

// Partner Businesses
export function useGetAllPartnerBusinesses({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetPartnerBusinessesRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ENDPOINTS.GET_PARTNER_BUSINESSES],
    queryFn: ({ signal }) =>
      getAllPartnerBusinesses({
        signal,
        params: {
          path: {
            businessId: params?.path.businessId,
          },
          query: {
            ...params?.query,
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}
