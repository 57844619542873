import { sub } from 'date-fns';

import { TInviteBusinessTeamMemberRequest, TUpdateBusinessTeamMembersDetailsRequest } from '@/services/business';

const enum MEMBER_TEAM {
  PRODUCT = 'Product',
  DESIGN = 'Design',
  HR = 'HR',
}

const enum MEMBER_TYPE {
  OWNER = 'Owner-Admin',
  ADMIN = 'Admin',
  MEMBER = 'Member',
  REMOVED = 'Removed',
}

export type TBusinessAuthMethod = NonNullable<
  TInviteBusinessTeamMemberRequest['requestBody']
>['content']['application/json']['user_auth_method'];

export type TAccountTypeSelectOption = {
  value: NonNullable<
    TUpdateBusinessTeamMembersDetailsRequest['requestBody']
  >['content']['application/json']['account_type'];
  label: string;
};

export type TBusinessAuthMethodOption = {
  value: NonNullable<
    TInviteBusinessTeamMemberRequest['requestBody']
  >['content']['application/json']['user_auth_method'];
  label: string;
};

export const accountTypeOptions: TAccountTypeSelectOption[] = [
  { value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'view_only', label: 'View only' },
];

export function getAccountTypeLabel(value: TAccountTypeSelectOption['value']): string {
  const option = accountTypeOptions.find((opt) => opt.value === value);
  return option ? option.label : 'Unknown';
}

export const teamTypeOptions: string[] = [
  'Product',
  'Design',
  'HR',
  'Admin',
  'Management',
  'Technology',
  'Sales and Marketing',
  'Finance',
  'Customer Support',
  'Legal',
  'Operations',
  'Project',
];

export type TBusinessMember = (typeof businessMembers)[number];

export const businessMembers = [
  {
    id: 1,
    name: 'John Doe',
    role: [MEMBER_TEAM.PRODUCT, MEMBER_TEAM.DESIGN],
    type: MEMBER_TYPE.OWNER,
    email: 'john@example.com',
    last_active: sub(new Date(), { days: Math.floor(30 * Math.random()) }),
  },
  {
    id: 2,
    name: 'Jane Doe',
    role: [MEMBER_TEAM.DESIGN, MEMBER_TEAM.HR],
    type: MEMBER_TYPE.ADMIN,
    email: 'jane@example.com',
    last_active: sub(new Date(), { days: Math.floor(30 * Math.random()) }),
  },
  {
    id: 3,
    name: 'Bob Smith',
    role: [MEMBER_TEAM.HR, MEMBER_TEAM.PRODUCT],
    type: MEMBER_TYPE.MEMBER,
    email: 'bob@example.com',
    last_active: sub(new Date(), { days: Math.floor(30 * Math.random()) }),
  },
  {
    id: 4,
    name: 'Alice Johnson',
    role: [MEMBER_TEAM.PRODUCT],
    type: MEMBER_TYPE.MEMBER,
    email: 'alice@example.com',
    last_active: sub(new Date(), { days: Math.floor(30 * Math.random()) }),
  },
];
