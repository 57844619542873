import { z } from 'zod';

export const inviteTeamSchema = z.object({
  account_type: z.string().min(1, { message: 'Account type is required' }),
  email: z.string().email({ message: 'Email is required' }),
  team: z.array(z.string()).min(1, { message: 'Team is required' }),
  first_name: z.string().min(1, { message: 'First name is required' }),
  last_name: z.string().min(1, { message: 'Last name is required' }),
  user_auth_method: z.string().nullable(),
});

export type TInviteTeamFormData = z.infer<typeof inviteTeamSchema>;
