import client, { TRequestOptions } from '@/lib/api';

import { ONCORE_POSITION_ENDPOINTS } from './oncore-position.endpoints';
import { TGetOncorePositionValidationRequest } from './oncore-position.types';

export const validateOncorePosition = async ({
  signal,
  params,
}: TRequestOptions<TGetOncorePositionValidationRequest>) => {
  const { data } = await client.GET(ONCORE_POSITION_ENDPOINTS.VALIDATE_ONCORE_POSITION, { signal, params });

  return data?.data;
};
