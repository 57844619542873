import React from 'react';

interface IRoleTitleProps {
  title: string;
  logo?: string;
}

export const RoleTitle: React.FC<IRoleTitleProps> = ({ title, logo }) => {
  return (
    <div className="break-words">
      <span className="inline">
        {title}
        {logo && (
          <img
            src={logo}
            alt="Brand Logo"
            className="ml-1 inline-block h-4 w-4 object-contain align-middle"
          />
        )}
      </span>
    </div>
  );
};
