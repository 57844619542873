import { useEffect } from 'react';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { createFileRoute, Link, redirect, useRouter } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import Spinner from '@/components/ui/spinner';

import FullPageLoader from '@/components/full-page-loader';
import SupportNavLink from '@/components/navigation/support-nav-link';

import { useGetOncorePositionValidation } from '@/hooks/business-roles';
import useAuth from '@/hooks/useAuth';

import { USER_TYPE } from '@/services/user';

export const Route = createFileRoute('/integrations/oncore/$tenantId/positions/$positionId')({
  component: OncorePositionPage,
  beforeLoad: async ({ context }) => {
    const { user } = context.auth;
    if (!user) {
      // redirect to login
      throw context.auth.loginWithRedirect({
        // return to original intended route after login
        appState: { returnTo: window.location.href },
        authorizationParams: {
          screen_hint: 'login',
          connection: 'oncore-openid',
        },
      });
    } else {
      // check role and redirect
      if (user?.app_user_type !== USER_TYPE.BUSINESS) {
        throw redirect({ to: '/' });
      }

      // TODO: Validate user is from Oncore business (maybe we don't need it)
    }
  },
});

function OncorePositionPage() {
  const { tenantId, positionId } = Route.useParams();
  const router = useRouter();
  const { user } = useAuth();

  const { data: positionValidationResult, isPending } = useGetOncorePositionValidation({
    params: {
      path: {
        tenantId,
        positionId,
      },
    },
    reactQueryOptions: {
      enabled: !!user,
    },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (positionValidationResult) {
        router.navigate({
          to: '/business/role/$roleId',
          params: { roleId: positionValidationResult?.upplftRole?.id?.toString() },
        });
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionValidationResult]);

  if (isPending) return <FullPageLoader className="w-full" />;

  return (
    <main className="flex h-screen w-screen justify-center space-y-6 py-8">
      <Button
        variant="tertiary"
        onClick={() => {
          router.navigate({
            to: '/business',
          });
        }}
      >
        <UilArrowLeft size={18} />
        Home
      </Button>
      <div className="flex gap-8">
        <section className="flex-grow justify-center py-8">
          {/* Valid Oncore Position */}
          {positionValidationResult && (
            <div className="flex justify-center">
              <Card className="w-[32.75rem] justify-center p-8 shadow-lg">
                <CardContent className="flex flex-col justify-center space-y-2">
                  <CardTitle className="text-center">
                    {positionValidationResult?.upplftRole?.title || 'Upplft Role'}
                  </CardTitle>
                  <p className="text-center">
                    Redirecting you to role page...{' '}
                    <span>
                      {' '}
                      <Spinner className="inline-block size-6" />{' '}
                    </span>
                  </p>
                  <p className="text-center">If you are not redirected automatically, click the button below.</p>
                  <Button variant="link">
                    <Link
                      to="/business/role/$roleId"
                      params={{ roleId: positionValidationResult?.upplftRole?.id?.toString() }}
                    >
                      Click here to view role
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            </div>
          )}
          {/* Invalid Oncore Position */}
          {!positionValidationResult && (
            <div className="flex justify-center">
              <Card className="w-[32.75rem] justify-center p-8 shadow-lg">
                <CardContent className="flex flex-col items-center justify-center space-y-2">
                  <CardTitle className="text-center">Invalid Link</CardTitle>
                  <p className="text-center">
                    The link you are trying to access is invalid. If you believe this is an error, please contact
                    support.
                  </p>
                  <SupportNavLink title="Contact Support" />
                </CardContent>
              </Card>
            </div>
          )}
        </section>
      </div>
    </main>
  );
}
