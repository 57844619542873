import { paths } from '@/lib/api/v1';

export const BUSINESS_ENDPOINTS = {
  GET_BUSINESS_BY_ID: '/v1/business/{id}',
  GET_CURRENT_BUSINESS: '/v1/businesses/me',
  UPDATE_CURRENT_BUSINESS: '/v1/businesses/me',
  GET_ALL_CALENDAR_EVENTS: '/v1/businesses/me/events',
  GET_BUSINESS_AUTH_METHODS: '/v2/business/{businessId}/auth-methods',
  // Invitations setup
  BUSINESS_INVITE_SETUP_VERIFY_TOKEN: '/v2/business/invite/setup/verify/{token}',
  BUSINESS_INVITE_SETUP: '/v2/business/invite/setup',
  // Team Members
  INVITE_BUSINESS_TEAM_MEMBER: '/v2/business/{businessId}/members/invite',
  GET_BUSINESS_TEAM_MEMBERS: '/v2/business/{businessId}/members',
  GET_BUSINESS_TEAM_MEMBERS_DETAILS: '/v2/business/{businessId}/members/{memberId}',
  RESEND_INVITE_BUSINESS_TEAM_MEMBER: '/v2/business/{businessId}/members/{memberId}/resend-invite',
  RESET_BUSINESS_TEAM_MEMBER_PASSWORD: '/v2/business/{businessId}/members/{memberId}/reset-password',
  UPDATE_BUSINESS_TEAM_MEMBER: '/v2/business/{businessId}/members/{memberId}',
  REMOVE_BUSINESS_TEAM_MEMBER: '/v2/business/{businessId}/members/{memberId}',
  // Team Partners
  INVITE_BUSINESS_PARTNER: '/v2/business/{businessId}/partners/invite',
  GET_BUSINESS_PARTNERS: '/v2/business/{businessId}/partners',
  GET_BUSINESS_PARTNERS_DETAILS: '/v2/business/{businessId}/partners/{partnerId}',
  RESEND_INVITE_BUSINESS_PARTNER: '/v2/business/{businessId}/partners/{partnerId}/resend-invite',
  UPDATE_BUSINESS_PARTNER_DETAILS: '/v2/business/{businessId}/partners/{partnerId}',
  REMOVE_BUSINESS_PARTNER: '/v2/business/{businessId}/partners/{partnerId}',
  // Partner Businesses
  GET_PARTNER_BUSINESSES: '/v2/partners/{businessId}/business',
} satisfies Record<string, keyof paths>;
